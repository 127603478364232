// 管理ページ共通で利用するスタイル
@charset "utf-8";

@import "base.scss";

// テーブルのヘッダーの背景色を変更
$table-head-background-color: findLightColor($cyan);

@import "bulma/bulma.sass";

// テーブルの幅を調整
// 日付カラム
.column-date {
    width: 14rem;
}

// 幅4remのカラム
.column-width-4 {
    width: 4rem;
}

// 幅8remのカラム
.column-width-8 {
    width: 8rem;
}

// コメントカラム
.column-comment {
    max-width: 30vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// フッターの高さを調整
.footer.navbar {
    min-height: 1rem;
}

// <input type="color" list="..."> で矢印が表示されない問題の対策
// from bulma/sass/form/shared.sass
$input-arrow: $link !default;

// from bulma/sass/form/select.sass
input[type="color"][list]::after {
    @extend %arrow;
    border-color: $input-arrow;
    @include ltr-position(1.125em);
    z-index: 4;
}
