// 共通で利用するスタイル
@charset "utf-8";

// Font AwesomeのCSSを読み込む
@import "@fortawesome/fontawesome-free/css/all.css";
@import "tippy.js/dist/tippy.css";

// Bulmaの変数を上書き
// https://bulma.io/documentation/customize/variables/

// sans-serifフォントを変更
$family-sans-serif: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans","BIZ UDPGothic",Meiryo,sans-serif;
// spacingの値を追加
$spacing-values: ("0": 0, "1": 0.25rem, "2": 0.5rem, "3": 0.75rem, "4": 1rem, "5": 1.5rem, "6": 3rem, "7": 6rem, "8": 12rem, "auto": auto);

// 初期値と関数を使うために必要
@import "bulma/sass/utilities/initial-variables.sass";
@import "bulma/sass/utilities/functions.sass";

// pre-wrapを使うためのスタイル
.is-pre-wrap {
    white-space: pre-wrap;
}

// フッターに内容が隠れるのを防止
body {
    padding-bottom: 3rem !important;
}